var SODODays = new function () {
    this.init = function () {
        this.menu();
    };

    this.menu = function () {
        const headerNavBtn = document.querySelector('.js-header-nav-button');
        const headerNav = document.querySelector('.js-header-nav');
        headerNavBtn.addEventListener('click', function (event) {
            event.preventDefault();
            headerNav.classList.toggle('active');
            this.classList.toggle('active');
        });
    };
};

if (document.readyState !== 'loading') {
    SODODays.init();
}
else {
    document.addEventListener("DOMContentLoaded", function () { 
        SODODays.init();
    });
}
